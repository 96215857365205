import { endOfDay, isSameDay, startOfDay } from 'date-fns'
import type { DateRange } from 'react-day-picker'

export function getDateRange(dateRange?: DateRange) {
  if (dateRange?.from) {
    if (!dateRange.to || isSameDay(dateRange.from, dateRange.to)) {
      return `${startOfDay(dateRange.from).getTime()},${endOfDay(
        dateRange.from,
      ).getTime()}`
    }
    return `${dateRange.from.getTime()},${endOfDay(dateRange.to).getTime()}`
  }
  return `${startOfDay(new Date(0)).getTime()},${endOfDay(new Date()).getTime()}`
}
