import z from 'zod'

export const StoreSchema = z.object({
  id: z.number(),
  name: z.string(),
  country_code: z.string(),
  store_id: z.string(),
  email: z.string().nullable().optional(),
  timezone: z.string(),
  open_hour: z.number(),
  close_hour: z.number(),
  reserved_hours: z.number(),
  active: z.boolean(),
})
export type Store = z.infer<typeof StoreSchema>

export const StoresSchema = z.array(StoreSchema)
export type Stores = z.infer<typeof StoresSchema>
