import z from 'zod'

import { LanguageCode } from '~/i18n'

export const ArticleTranslationSchema = z.object({
  id: z.number(),
  article_id: z.number().optional(),
  title: z.string().nullable().optional(),
  price: z.number(),
  currency_code: z.string().optional(),
  description: z.string().nullable().optional(),
  languageCode: z.nativeEnum(LanguageCode),
  by_default: z.boolean(),
  benefits: z.string().nullable().optional(),
  benefit_summary: z.string().nullable().optional(),
  designers: z.string().nullable().optional(),
  good_to_knows: z.string().nullable().optional(),
  materials: z.string().nullable().optional(),
  care_instructions: z.string().nullable().optional(),
  technicalCompliance: z.string().nullable().optional(),
  technicalEnergyEfficiencyImage: z.string().nullable().optional(),
  technicalEnergyLabelImage: z.string().nullable().optional(),
  language_id: z.number().optional(),
})

export const ArticleSchema = z.object({
  id: z.number(),
  lang_code: z.string().optional(),
  title: z.string().nullable().optional(),
  description: z.string().nullable().optional(),
  price: z.number().optional(),
  qty: z.number().optional(),
  article_id: z.string(),
  iows_images: z.array(z.string()),
  currency_code: z.string(),
  articleTranslations: z.array(ArticleTranslationSchema).nullable().optional(),
})
export type ArticleTranslation = z.infer<typeof ArticleTranslationSchema>
export type Article = z.infer<typeof ArticleSchema>
